import { FigureInterface } from './Figure.interface';

export const HOME_IMAGES: Array<FigureInterface> = [
    {
        id: 'cruises-1000',
        src: 'https://images.unsplash.com/photo-1588151626366-a2df693aa0a7',
        alt: 'cruise ship',
        credit: 'Photo by Adam Gonzales on Unsplash',
        caption: 'Cruises',
        prompt: 'Book your cruise',
        isExternal: true,
        href: 'https://terryfrancis.inteletravel.com/',
    },
    {
        id: 'flights-1000',
        src: 'https://images.unsplash.com/photo-1421789497144-f50500b5fcf0',
        alt: 'airline departures',
        credit: 'Photo by Matthew Smith on Unsplash',
        caption: 'Flights',
        prompt: 'Compare and book flights with ease',
        isExternal: false,
        href: '/flights',
    },
    {
        id: 'hotels-1100',
        src: 'https://images.unsplash.com/photo-1584132967334-10e028bd69f7',
        alt: 'resort pool',
        credit: 'Photo by Sasha Kaunas on Unsplash',
        caption: 'Hotels',
        prompt: 'Book your accommodations',
        isExternal: false,
        href: '/stays',
    },
    {
        id: 'villas-1000',
        src: 'https://images.unsplash.com/photo-1564501049412-61c2a3083791',
        alt: 'villa',
        credit: 'Photo by Ciudad Maderas on Unsplash',
        caption: 'Villas',
        prompt: `Find a villa that you'll love`,
        isExternal: true,
        href: 'https://terryfrancis.inteletravel.com/',
    },
    {
        id: 'cars-1000',
        src: 'https://images.unsplash.com/photo-1570636802145-8cda1335fe54',
        alt: 'cars',
        credit: 'Photo by Carles Rabada on Unsplash',
        caption: 'Car Rentals',
        prompt: 'Compare deals from the biggest car rental companies',
        isExternal: false,
        href: '/cars',
    },
    {
        id: 'insure-1000',
        src: 'https://images.unsplash.com/photo-1578894381163-e72c17f2d45f',
        alt: 'travel map',
        credit: 'Photo by Charlotte Noelle on Unsplash',
        caption: 'Insure It',
        prompt: 'Protect your trip',
        isExternal: true,
        href: 'https://terryfrancis.inteletravel.com/',
    },
];
